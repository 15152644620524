.verify-screen {
    position: fixed;
    background: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.verify-screen p {
    color: white;
    font-size: 2rem;
}